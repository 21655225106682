import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";

import "./styles.scss";
import { useEffect, useState } from "react";
import { LocalStorageKeys } from "../../constants/constants";
import { useSelector } from "react-redux";
import {
  getAllXRPTransactions,
  getAccountBalance,
  getAllAccountNFTs,
} from "../../services-common/xumm-api-service";
import Card from "react-bootstrap/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { setShowScreenLoader } from "../../redux/screenLoader/ScreenLoaderSlice";
import TopUpModal from "../../components/TopUpModal/TopUpModal";
import { checkAccountActivation } from "../../services-common/web3auth-service";
import {
  openTopUpModal,
  closeTopUpModal,
} from "../../redux/TopUpModel/topUpModalSlice";
import {
  closeTopUpWithDialogModal,
  openTopUpWithDialogModal,
} from "../../redux/TopUpWithDialogModel/topUpWithDialogModelSlice";
import TopUpWithDialogModel from "../../components/TopUpWithDialogModel/TopUpWithDialogModel";
import PaginatedTable from "./PaginatedTable";

const AccountTransactions = () => {
  const dispatch = useDispatch();
  const [accountAddress, setAccountAddress] = useState(null);
  const [allTransactions, setAllTransactions] = useState({
    xrp: [],
    tpq: [],
  });
  const [currencyType, setCurrencyType] = useState({
    xrp: true,
    tpq: false,
  });
  const [mappingTransactions, setMappingTransactions] = useState(
    allTransactions.xrp
  );
  const [accountBalance, setAccountBalance] = useState({
    xrp: 0,
    usd: 0,
    tpq: 0,
  });
  const [tableDataLoading, setTableDataLoading] = useState(false);
  const [balanceLoading, setBalanceLoading] = useState(false);
  const userKey = localStorage.getItem(LocalStorageKeys.AccountAddress);
  const { isTopUpModalOpen } = useSelector((state) => state.topUpModal);
  const { isTopUpWithDialogModelOpen } = useSelector(
    (state) => state.topUpModalWithDialog
  );
  const [isUserAccountActivated, setIsUserAccountActivated] = useState(false);
  const [radioValue, setRadioValue] = useState("1");
  const openModal = () => {
    dispatch(openTopUpModal());
  };

  const closeModal = () => {
    dispatch(closeTopUpModal());
  };

  const openDialogModel = () => {
    dispatch(openTopUpWithDialogModal());
  };

  const closeDialogModel = () => {
    dispatch(closeTopUpWithDialogModal());
  };

  const radios = [
    { name: "XRP transactions", value: "1" },
    { name: "TPQ transactions", value: "2" },
  ];

  const getAllXRPTransactionsData = async () => {
    setTableDataLoading(true);
    try {
      const res = await getAllXRPTransactions(
        localStorage.getItem(LocalStorageKeys.AccountAddress)
      );

      const nftRes = await getAllAccountNFTs(
        localStorage.getItem(LocalStorageKeys.AccountAddress)
      );
      // console.log("NFT: ", nftRes);
      setTableDataLoading(false);
      setAllTransactions(res);
      dispatch(setShowScreenLoader(false));
    } catch (e) {
      setTableDataLoading(false);
      dispatch(setShowScreenLoader(false));
      console.log(e);
    }
  };

  const getAccountBalanceData = async () => {
    setBalanceLoading(true);
    try {
      const res = await getAccountBalance(
        localStorage.getItem(LocalStorageKeys.AccountAddress)
      );
      setBalanceLoading(false);
      setAccountBalance(res);
      dispatch(setShowScreenLoader(false));
    } catch (e) {
      setBalanceLoading(false);
      dispatch(setShowScreenLoader(false));
      console.log(e);
    }
  };

  useEffect(() => {
    getAllXRPTransactionsData();
    getAccountBalanceData();
  }, []);

  useEffect(() => {
    dispatch(setShowScreenLoader(true));
    const acc = localStorage.getItem(LocalStorageKeys.AccountAddress);
    if (acc && acc.length > 0) {
      setAccountAddress(acc);
    }

    if (accountAddress) getAllXRPTransactionsData();
    else {
      dispatch(setShowScreenLoader(false));
    }
  }, [accountAddress]);

  const onRefresh = () => {
    dispatch(setShowScreenLoader(true));
    getAllXRPTransactionsData();
    getAccountBalanceData();
  };

  const toggleTransactions = (value) => {
    setRadioValue(value);
    if (value === "2") {
      setCurrencyType((prev) => ({
        ...prev,
        xrp: false,
        tpq: true,
      }));
      setMappingTransactions([...allTransactions.tpq]);
    } else {
      setMappingTransactions([...allTransactions.xrp]);
      setCurrencyType((prev) => ({
        ...prev,
        xrp: true,
        tpq: false,
      }));
    }
  };

  useEffect(() => {
    const checkAccountActivatedStatus = async () => {
      if (!userKey) return;
      const activated = await checkAccountActivation(userKey);
      if (activated) {
        setIsUserAccountActivated(true);
      } else {
        setIsUserAccountActivated(false);
      }
    };
    checkAccountActivatedStatus();
  }, []);

  useEffect(() => {
    setCurrencyType((prev) => ({
      ...prev,
      xrp: true,
      tpq: false,
    }));
    setMappingTransactions(allTransactions.xrp);
  }, [allTransactions]);

  return (
    <>
      {isUserAccountActivated ? (
        <Container style={{ minHeight: "85vh" }}>
          <Row>
            <Col lg={10}>
              <div
                className="page-header mt-4"
                style={{
                  color: "rgb(44 44 118)",
                  fontWeight: 700,
                  fontSize: "50px",
                }}
              >
                My Account
              </div>
              <div className="account-address">{accountAddress}</div>
            </Col>
            <Col lg={2}>
              <div className="page-header mt-5">
                <Button
                  variant="warning"
                  onClick={onRefresh}
                  style={{ color: "rgb(44 44 118)", fontWeight: 700 }}
                >
                  Refresh <FontAwesomeIcon icon={faArrowsRotate} />
                </Button>
              </div>
            </Col>
          </Row>

          <Row className={`mt-5`}>
            <Col lg={5}>
              <Card
                className={`text-center p-5 balance-card`}
                style={{ position: "relative" }}
              >
                <div
                  style={{ position: "absolute", left: 10, top: 5 }}
                  className={`card-label`}
                >
                  {" "}
                  TPQ balance
                </div>
                <Card.Body style={{ marginTop: "33px" }}>
                  <div className="ever-balance-value" title={``}>
                    {(tableDataLoading && balanceLoading) ||
                    (!tableDataLoading && balanceLoading) ||
                    (tableDataLoading && !balanceLoading) ? (
                      <p>Loading...</p>
                    ) : (
                      <>
                        {accountBalance.tpq} <br />
                        <span
                          style={{
                            fontSize: "30px",
                            lineHeight: "12px",
                          }}
                        >
                          {/* ({accountBalance.usd} USD) */}
                          TripQ Currency
                        </span>
                      </>
                    )}
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={2}></Col>
            <Col lg={5}>
              <Card
                className={`text-center p-5 balance-card`}
                style={{ position: "relative" }}
              >
                <div
                  style={{ position: "absolute", left: 10, top: 5 }}
                  className={`card-label`}
                >
                  {" "}
                  XRP balance
                </div>
                <Card.Body style={{ marginTop: "33px" }}>
                  <div
                    className="ever-balance-value"
                    title={`USD ${accountBalance.usd}`}
                  >
                    {(tableDataLoading && balanceLoading) ||
                    (!tableDataLoading && balanceLoading) ||
                    (tableDataLoading && !balanceLoading) ? (
                      <p>Loading...</p>
                    ) : (
                      <>
                        {accountBalance.xrp} <br />
                        <span
                          style={{
                            fontSize: "30px",
                            lineHeight: "12px",
                          }}
                        >
                          ({accountBalance.usd} USD)
                        </span>
                      </>
                    )}
                  </div>
                </Card.Body>
              </Card>
              {/* <div
                style={{
                  width: "100%",
                  padding: "20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button variant="outline-warning" onClick={openModal}>
                  Make a Transaction
                </Button>
              </div>
              <p className="activation-message">
                You're all set. Make transactions now
              </p> */}
            </Col>
          </Row>

          {allTransactions.length !== 0 && (
            <Row className="mt-5 mb-2">
              <Col>
                <h2 style={{ color: "rgb(44 44 118)" }}>Transactions</h2>
              </Col>
            </Row>
          )}
          {allTransactions?.xrp?.length !== 0 &&
            allTransactions?.tpq?.length !== 0 && (
              <Row className={`mb-3`}>
                <Col>
                  <ButtonGroup>
                    {radios.map((radio, idx) => (
                      <ToggleButton
                        key={idx}
                        id={`radio-${idx}`}
                        type="radio"
                        variant={"outline-warning"}
                        name="radio"
                        value={radio.value}
                        checked={radioValue === radio.value}
                        onChange={(e) => {
                          toggleTransactions(e.currentTarget.value);
                        }}
                      >
                        {radio.name}
                      </ToggleButton>
                    ))}
                  </ButtonGroup>
                </Col>
              </Row>
            )}
          <Row className="mt-1">
            <Col>
              {
                <PaginatedTable
                  mappingTransactions={mappingTransactions}
                  accountAddress={accountAddress}
                  currencyType={currencyType}
                />
              }
            </Col>
          </Row>
        </Container>
      ) : (
        <Container
          style={{
            width: "100%",
            heigth: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
            paddingTop: "50px",
          }}
        >
          <p className="activation-message" style={{ width: "100%" }}>
            🌟 Your wallet is ready! Add some funds to get started with secure
            transactions.
          </p>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "10px",
              padding: "10px",
            }}
          >
            <Button variant="outline-warning" onClick={openModal}>
              Top Up With External Wallet
            </Button>
            <Button variant="outline-warning" onClick={openDialogModel}>
              Top Up With Prepaid Card
            </Button>
          </div>
        </Container>
      )}
      {isTopUpModalOpen && (
        <TopUpModal
          onClose={closeModal}
          isUserAccountActivated={isUserAccountActivated}
        />
      )}

      {isTopUpWithDialogModelOpen && (
        <TopUpWithDialogModel
          onClose={closeDialogModel}
          isUserAccountActivated={isUserAccountActivated}
        />
      )}
    </>
  );
};

export default AccountTransactions;
