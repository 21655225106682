import { configureStore } from "@reduxjs/toolkit";
import visibleReducer from "./visibility/visibleSlice";
import registerCustomerReducer from "./registerCustomer/registerCustomerSlice";
import transactionListenerReducer from "./transactionListener/transactionListenerSlice";
import loginStateReducer from "./LoginState/LoginStateSlice";
import selectionDetailsReducer from "./SelectionDetails/SelectionDetailsSlice";
import ScreenLoaderReducer from "./screenLoader/ScreenLoaderSlice";
import AiHotelSearchStateReducer from "./AiHotelSearchState/AiHotelSearchStateSlice";
import bookingCustomerReducer from "./BookingCustomer/BookingCustomerSlice";
import MoreAiHotelSearchStateReducer from "./AiHotelSearchState/MoreAiSearchStateSlice";
import topUpModalReducer from "./TopUpModel/topUpModalSlice";
import paymentSelectionTopUpSocialReducer from "./PaymentSelectionTopUpSocial/PaymentSelectionTopUpSocialSlice";
import topUpWithDialogModelReducer from "./TopUpWithDialogModel/topUpWithDialogModelSlice";
import nFTSelectBarReducer from "./NFTSelectBar/nFTSelectBarSlice";

// Load loginState and nftSelectBar from localStorage
const loadState = () => {
  try {
    const serializedLoginState = localStorage.getItem("loginState");
    const serializedNftSelectBar = localStorage.getItem("nftSelectBar");

    const loginState = serializedLoginState
      ? JSON.parse(serializedLoginState)
      : undefined;
    const nftSelectBar = serializedNftSelectBar
      ? JSON.parse(serializedNftSelectBar)
      : undefined;

    return {
      loginState,
      nftSelectBar,
    };
  } catch (err) {
    console.error("Could not load state", err);
    return undefined;
  }
};

// Save specific slices of state to localStorage
const saveState = (state) => {
  try {
    const { loginState, nftSelectBar } = state;

    // Save loginState to localStorage
    const serializedLoginState = JSON.stringify(loginState);
    localStorage.setItem("loginState", serializedLoginState);

    // Save nftSelectBar to localStorage
    const serializedNftSelectBar = JSON.stringify(nftSelectBar);
    localStorage.setItem("nftSelectBar", serializedNftSelectBar);
  } catch (err) {
    console.error("Could not save state", err);
  }
};

// Load initial state for loginState and nftSelectBar
const preloadedState = loadState();

export const store = configureStore({
  reducer: {
    visibility: visibleReducer,
    registerCustomer: registerCustomerReducer,
    listenedTransactions: transactionListenerReducer,
    loginState: loginStateReducer,
    selectionDetails: selectionDetailsReducer,
    screenLaoder: ScreenLoaderReducer,
    AiHotelSearchState: AiHotelSearchStateReducer,
    MoreAiHotelSearchState: MoreAiHotelSearchStateReducer,
    bookingCustomer: bookingCustomerReducer,
    topUpModal: topUpModalReducer,
    paymentSelectionTopUpSocial: paymentSelectionTopUpSocialReducer,
    topUpModalWithDialog: topUpWithDialogModelReducer,
    nftSelectBar: nFTSelectBarReducer,
  },
  preloadedState,
});

// Subscribe to store updates and save loginState and nftSelectBar
store.subscribe(() => {
  const state = store.getState();
  saveState({
    loginState: state.loginState,
    nftSelectBar: state.nftSelectBar,
  });
});
