import NFTList from "../../components/UserNFT/NFTList/NFTList";
import "./usernft.scss";
import SellOffersForUser from "../../components/UserNFT/SellOffersForUser/SellOffersForUser";
import NFTTopBar from "../../components/UserNFT/NFTTopBar/NFTTopBar";
import { useSelector } from "react-redux";
import MarketplaceNFT from "../../components/UserNFT/MarketplaceNFT/MarketplaceNFT";

const UserNFT = () => {
  const { marketplace, mynft, myselloffers } = useSelector(
    (state) => state.nftSelectBar
  );
  return (
    <div className="main_container_usernft">
      <NFTTopBar />
      {/* NFT ITEMS CONTAINER */}
      <div className="nft-items-container">
        {mynft && <NFTList />}
        {marketplace && <MarketplaceNFT />}
        {myselloffers && <SellOffersForUser />}
      </div>
    </div>
  );
};

export default UserNFT;
