import { useState, useEffect } from "react";
import "./styles.scss";
import BookingDetails from "../../components/BookingDetails/index";
import StripeCheckout from "../../components/Payment/StripeCheckout";
import CoingatePayment from "../../components/Payment/CGPayment";
import PaymentSelection from "../../components/Payment/PaymentSelection";
import BookedHotelDetails from "../../components/BookedHotelDetails/index";
import { Row, Col } from "reactstrap";
import MainContainer from "../../layout/MainContainer";
import CustomerRegistration from "../../components/CustomerRegistration/CustomerRegistration";
import BookedHotelPrice from "../../components/BookedHotelPrice";
import "../../styles/layout_styles.scss";
import { useSelector } from "react-redux";
import {
  LocalStorageKeys,
  LoginMethods,
  ReservationStatus,
  ReservationWaitingTime,
} from "../../constants/constants";
import ReservationService from "../../services-domain/hotel-service";
import { mintNFT_XRPL } from "../../services-common/xumm-api-service";
import { mintNFT_SOCIAL } from "../../services-common/web3auth-service";
import NFTService from "../../services-domain/nft-service";
import { v4 as uuidv4 } from "uuid";

const ReservationForm = () => {
  const reservationService = new ReservationService();
  const nftService = new NFTService();
  const selectionDetails = useSelector((state) => state.selectionDetails);
  const [selectedRoomDetails, setSelectedRoomDetails] = useState([]);
  const [searchDetails, setSearchDetails] = useState({
    Name: "",
    Address: "",
    StarRate: 0,
    Facilities: [],
  });
  const [selectionStrings, setSelectionStrings] = useState({
    RoomId: 1,
    RoomCount: 2,
    costPerRoom: 50,
    roomName: "Deluxe",
  });
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [disableConfirm, setDisableConfirm] = useState(true);
  const [paymentEnabled, setPaymentEnabled] = useState(false);
  const [selectedGateway, setSelectedGateway] = useState("");
  const [totalPrice, setTotalPrice] = useState(0);
  const loginState = useSelector((state) => state.loginState);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    let selectedDetails =
      selectionDetails[
        localStorage.getItem(LocalStorageKeys.HotelSelectionDetails)
      ];

    if (!selectedDetails) {
      selectedDetails = JSON.parse(
        localStorage.getItem(LocalStorageKeys.HotelSelectionDetails)
      );
    }
    if (
      selectedDetails &&
      selectedDetails.RoomTypes &&
      selectedDetails.RoomTypes.length > 0
    ) {
      setSelectedRoomDetails(selectedDetails.RoomTypes);
      let totalPrice = 0;
      selectedDetails.RoomTypes.forEach((element) => {
        totalPrice +=
          parseFloat(element.roomData.Price).toFixed(4) *
          element.count *
          parseInt(selectedDetails.Nights);
      });

      setTotalPrice(totalPrice);
    }

    setSearchDetails(selectedDetails);
  }, []);

  const handleConfirm = (finalDetails) => {
    console.log("Reservation confirmed:", finalDetails);
  };
  const handlePaymentEnabled = (data) => {
    setPaymentEnabled(data);
  };

  const handleBackToPayment = (data) => {
    setPaymentEnabled(data);
  };

  const handleSelectedGateway = (data) => {
    setSelectedGateway(data);
  };

  const handleMakeReservation = async (reservationDetails) => {
    let roomTypes = [];
    const userWalletAddress = localStorage.getItem(
      LocalStorageKeys.AccountAddress
    );

    const isSocialLogin =
      localStorage.getItem(LocalStorageKeys.LoginMethod) ===
      LoginMethods.SOCIAL;

    selectedRoomDetails.forEach((roomType) => {
      const roomData = {
        Id: roomType.roomData.Id,
        count: roomType.count,
      };
      roomTypes.push(roomData);
    });

    const customerDetails = JSON.parse(
      localStorage.getItem(LocalStorageKeys.BookingCustomer)
    );

    let status = ReservationStatus.PENDING_RESERVATION;
    if (
      new Date(reservationDetails.finishAfterTime) < new Date() ||
      new Date(reservationDetails.cancelAfterTime) >
        new Date(Date.now() + ReservationWaitingTime)
    ) {
      status = ReservationStatus.RESERVED;
    }

    const reservationCode = uuidv4();

    const data = {
      ReservationCode: reservationCode,
      WalletAddress: userWalletAddress,
      DestinationAddress: userWalletAddress,
      Price: totalPrice,
      FromDate: searchDetails.CheckIn,
      ToDate: searchDetails.CheckOut,
      NoOfNights: searchDetails.Nights,
      FirstName: customerDetails.firstName,
      LastName: customerDetails.lastName,
      Email: customerDetails.email,
      Country: customerDetails.country,
      Telephone: customerDetails.phoneNo,
      HotelId: searchDetails.HotelId,
      EscrowSequence: reservationDetails.sequence,
      IssuedUserToken: reservationDetails.issuedUserToken,
      Status: status,
      RoomTypes: roomTypes,
      FinishAfterTime: reservationDetails.finishAfterTime,
      CancelAfterTime: reservationDetails.cancelAfterTime,
      IsInternalWallet:
        localStorage.getItem(LocalStorageKeys.PayWithInternalWallet) === "true"
          ? true
          : false,
    };

    try {
      let reservationsRes = await reservationService.makeReservation(data);
      console.log("Reservation successful: ", reservationsRes);

      // mint NFT if reservation is successful
      if (reservationsRes) {
        console.log("===> Going to mint NFT");
        if (
          reservationsRes?.hotel?.ContactDetails &&
          typeof reservationsRes?.hotel?.ContactDetails === "string"
        ) {
          try {
            reservationsRes.hotel.ContactDetails = JSON.parse(
              reservationsRes.hotel.ContactDetails
            );
          } catch (error) {
            console.error(
              "Invalid JSON string in ContactDetails:",
              reservationsRes.hotel.ContactDetails,
              error
            );
          }
        }

        if (
          reservationsRes?.hotel?.Facilities &&
          typeof reservationsRes?.hotel?.Facilities === "string"
        ) {
          try {
            reservationsRes.hotel.Facilities = JSON.parse(
              reservationsRes.hotel.Facilities
            );
          } catch (error) {
            console.error(
              "Invalid JSON string in Facilities:",
              reservationsRes.hotel.Facilities,
              error
            );
          }
        }

        if (
          reservationsRes?.hotel?.Location &&
          typeof reservationsRes?.hotel?.Location === "string"
        ) {
          try {
            reservationsRes.hotel.Location = JSON.parse(
              reservationsRes.hotel.Location
            );
          } catch (error) {
            console.error(
              "Invalid JSON string in Location:",
              reservationsRes.hotel.Location,
              error
            );
          }
        }

        for (let room of reservationsRes?.roomTypes || []) {
          if (room?.Facilities && typeof room?.Facilities === "string") {
            try {
              room.Facilities = JSON.parse(room.Facilities);
            } catch (error) {
              console.error(
                "Invalid JSON string in Facilities:",
                room.Facilities,
                error
              );
            }
          }
        }

        const nftData = {
          reservationCode: reservationCode,
          hotel: reservationsRes?.hotel || {},
          price: data.Price,
          fromDate: data.FromDate,
          toDate: data.ToDate,
          noOfNights: data.NoOfNights,
          roomTypes: reservationsRes?.roomTypes || [],
        };

        if (isSocialLogin) {
          try {
            const nftEntityData = await mintNFT_SOCIAL(nftData);
            const response = await nftService.addNftData(nftEntityData);
            console.log("NFT table entry added =========> : ", response);
            return true;
          } catch (err) {
            throw err;
          }
        } else {
          try {
            const nftEntityData = await mintNFT_XRPL(nftData);
            const response = await nftService.addNftData(nftEntityData);
            console.log("NFT table entry added =========> : ", response);
            return true;
          } catch (err) {
            throw err;
          }
        }
      }
    } catch (e) {
      throw e;
    }
  };

  return (
    <>
      <MainContainer>
        <Row>
          <Col md={4}>
            <BookingDetails
              selectedRoomDetails={selectedRoomDetails}
              checkindate={searchDetails.CheckIn}
              checkoutdate={searchDetails.CheckOut}
              noOfDays={searchDetails.Nights}
              selections={JSON.stringify(selectionStrings)}
            />
            <BookedHotelPrice totalPrice={totalPrice} />
          </Col>

          <Col md={8}>
            <div>
              <BookedHotelDetails
                hotelName={searchDetails.Name}
                hotelAddress={searchDetails.Address}
                starRate={searchDetails.StarRate}
                image={searchDetails.Images}
              />

              {!paymentEnabled ? (
                <>
                  <CustomerRegistration
                    totalPrice={totalPrice}
                    disableConfirm={disableConfirm}
                    setDisableConfirm={setDisableConfirm}
                    confirmLoading={confirmLoading}
                    setConfirmLoading={setConfirmLoading}
                    setPaymentEnabled={handlePaymentEnabled}
                  />
                </>
              ) : (
                <>
                  {selectedGateway == "" ? (
                    <PaymentSelection
                      //totalPrice={totalPrice}
                      setSelectedGateway={handleSelectedGateway}
                      handleMakeReservation={handleMakeReservation}
                    />
                  ) : selectedGateway == "Stripe" ? (
                    <StripeCheckout totalPrice={totalPrice} />
                  ) : selectedGateway == "Coingate" ? (
                    <CoingatePayment totalPrice={totalPrice} />
                  ) : null}
                </>
              )}
            </div>
          </Col>
        </Row>
      </MainContainer>
    </>
  );
};

export default ReservationForm;
