import { useState } from "react";
import "./NFTActionButtons.scss";
import {
  burnNFT_XRPL,
  createNFTSellOfferXRPL,
  transferNFTOwnershipXRPL,
} from "../../../services-common/xumm-api-service";
import toast from "react-hot-toast";
import { LocalStorageKeys, LoginMethods } from "../../../constants/constants";
import {
  burnNFT_SOCIAL,
  createNFTSellOfferSOCIAL,
  transferNFTOwnershipSOCIAL,
} from "../../../services-common/web3auth-service";
import { useDispatch } from "react-redux";
import { setShowScreenLoader } from "../../../redux/screenLoader/ScreenLoaderSlice";

const NFTActionButtons = ({ nft, handleAcceptance = null }) => {
  const dispatch = useDispatch();
  const [showSellForm, setShowSellForm] = useState(false);
  const [showTransferForm, setShowTransferForm] = useState(false);
  const [sellPrice, setSellPrice] = useState("");
  const [destinationAddress, setDestinationAddress] = useState("");
  const [error, setError] = useState("");
  const loginMethod = localStorage.getItem(LocalStorageKeys.LoginMethod);

  const handleBurn = async () => {
    try {
      dispatch(setShowScreenLoader(true));
      let response;
      if (loginMethod === LoginMethods.SOCIAL) {
        response = await burnNFT_SOCIAL(nft?.nft?.NFTokenID);
      } else if (loginMethod === LoginMethods.XRPL) {
        response = await burnNFT_XRPL(nft?.nft?.NFTokenID);
      }
      console.log(response);
    } catch (error) {
      console.log("Error burning NFT: ", error);
    } finally {
      dispatch(setShowScreenLoader(false));
      window.location.reload();
    }
  };

  const toggleSellForm = () => {
    setShowSellForm((prev) => !prev);
  };

  const toggleTransferForm = () => {
    setShowTransferForm((prev) => !prev); // Toggle transfer form visibility
    setDestinationAddress(""); // Reset address when toggling
    setError(""); // Reset error when toggling
  };

  const handleSellSubmit = async (e) => {
    e.preventDefault();

    // Validate that sellPrice is a valid number
    if (!sellPrice || isNaN(sellPrice)) {
      setError("Please enter a valid number for the sell price.");
      return;
    }

    if (!nft?.nft) {
      setError("NFT not found.");
      return;
    }

    try {
      dispatch(setShowScreenLoader(true));
      let response;

      if (loginMethod === LoginMethods.SOCIAL) {
        response = await createNFTSellOfferSOCIAL(nft?.nft, sellPrice);
      } else if (loginMethod === LoginMethods.XRPL) {
        response = await createNFTSellOfferXRPL(nft?.nft, sellPrice);
      } else {
        setError("Invalid login method.");
        return;
      }
      console.log("Sell Offer Created: ", response);
      setShowSellForm(false);
      setSellPrice("");
      toast.success("Sell offer created successfully!");
    } catch (error) {
      toast.error("Error creating sell offer. Please try again.");
      console.log("Error creating sell offer: ", error);
    } finally {
      dispatch(setShowScreenLoader(false));
    }
  };

  const handleTransferSubmit = async (e) => {
    e.preventDefault();

    // Validate destination address
    if (!destinationAddress) {
      setError("Please enter a destination wallet address.");
      return;
    }

    if (!nft?.nft) {
      setError("NFT not found.");
      return;
    }

    try {
      dispatch(setShowScreenLoader(true));
      let response;
      if (loginMethod === LoginMethods.XRPL) {
        response = await transferNFTOwnershipXRPL(nft?.nft, destinationAddress);
      } else if (loginMethod === LoginMethods.SOCIAL) {
        response = await transferNFTOwnershipSOCIAL(
          nft?.nft,
          destinationAddress
        );
      }
      console.log("Ownership Transferred: ", response);
      setShowTransferForm(false);
      setDestinationAddress("");
      toast.success("Ownership transfer request send successfully!");
    } catch (error) {
      toast.error("Error transferring ownership. Please try again.");
      console.log("Error transferring ownership: ", error);
    } finally {
      dispatch(setShowScreenLoader(false));
    }
  };

  const handleNftAcceptance = async () => {
    if (typeof handleAcceptance === "function") {
      try {
        await handleAcceptance(nft?.offerId, nft?.nfToeknId);
      } catch (e) {
        console.log("Error accepting NFT: ", e);
      }
    }
  };

  return (
    <div className="nft-action-buttons">
      {nft?.nft && (
        <div className="buttons-container">
          <button
            className="ownership-button"
            onClick={toggleTransferForm}
            disabled={showSellForm}
          >
            {showTransferForm ? "Cancel Transfer" : "Transfer Ownership"}
          </button>

          <button
            className="sell-offer-button"
            onClick={toggleSellForm}
            disabled={showTransferForm}
          >
            {showSellForm ? "Cancel Sell Offer" : "Make Sell Offer"}
          </button>
          <button className="burn-button" onClick={handleBurn}>
            Burn
          </button>
        </div>
      )}

      {nft?.offerPrice && (
        <button className="accept-button" onClick={handleNftAcceptance}>
          Accept Offer
        </button>
      )}

      {showSellForm && (
        <form onSubmit={handleSellSubmit} className="sell-offer-form">
          <div>
            <label htmlFor="sellPrice">Sell Price (XRP):</label>
            <input
              type="text"
              required
              id="sellPrice"
              value={sellPrice}
              onChange={(e) => setSellPrice(e.target.value)}
              placeholder="Enter price in XRP"
            />
          </div>
          {error && <p className="error-message">{error}</p>}
          <button type="submit" className="submit-sell-offer-button">
            Submit
          </button>
        </form>
      )}

      {showTransferForm && (
        <form onSubmit={handleTransferSubmit} className="transfer-form">
          <div>
            <label htmlFor="destinationAddress">
              Destination Wallet Address:
            </label>
            <input
              type="text"
              required
              id="destinationAddress"
              value={destinationAddress}
              onChange={(e) => setDestinationAddress(e.target.value)}
              placeholder="Enter wallet address"
            />
          </div>
          {error && <p className="error-message">{error}</p>}
          <button type="submit" className="submit-transfer-button">
            Submit
          </button>
        </form>
      )}
    </div>
  );
};

export default NFTActionButtons;
