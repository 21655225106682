import React, { useEffect, useState } from "react";
import "./NFTTopBar.scss";
import { links_usernft } from "./links";
import { useDispatch } from "react-redux";
import { setNftShowComponent } from "../../../redux/NFTSelectBar/nFTSelectBarSlice";

const NFTTopBar = () => {
  const dispatch = useDispatch();
  const [activeLink, setActiveLink] = useState(() => {
    const savedLink = localStorage.getItem("activeNftLink");
    return savedLink ? parseInt(savedLink, 10) : null;
  });

  const handleClick = (id) => {
    setActiveLink(id);
    localStorage.setItem("activeNftLink", id);

    const show_comp_title = links_usernft.find((link) => link.id === id).title;
    let show_comp;
    if (show_comp_title === "Marketplace") {
      show_comp = "marketplace";
    } else if (show_comp_title === "My Offers") {
      show_comp = "myselloffers";
    } else if (show_comp_title === "My NFTs") {
      show_comp = "mynft";
    }
    dispatch(setNftShowComponent(show_comp));
  };

  useEffect(() => {
    if (activeLink === null && links_usernft.length > 0) {
      handleClick(links_usernft[0].id);
    } else {
      handleClick(activeLink);
    }
  }, [activeLink]);

  return (
    <div className="nft-sidebar-container">
      {links_usernft.map((link) => {
        const Icon = link.icon;
        return (
          <div
            key={link.id}
            className={`nft-sidebar-icon ${
              activeLink === link.id ? "active" : ""
            }`}
            onClick={() => handleClick(link.id)}
          >
            <div className="nft-sidebar-single-icon-container">
              <Icon size={24} className="nft-sidebar-single-icon" />
            </div>
            <span>{link.title}</span>
          </div>
        );
      })}
    </div>
  );
};

export default NFTTopBar;
