import { useEffect, useState } from "react";
import NFTService from "../../../services-domain/nft-service";
import { useDispatch } from "react-redux";
import { setShowScreenLoader } from "../../../redux/screenLoader/ScreenLoaderSlice";
import { getCleanedDataForNFT } from "../../../helpers/getCleanedDataForNFT";
import { FaExclamationCircle } from "react-icons/fa";
import "./MarketplaceNFT.scss";
import SellOfferForUser from "../SellOfferForUser/SellOfferForUser";
import { useLocation } from "react-router-dom";

function useQueryParams() {
  const { search } = useLocation();
  const [queryParams, setQueryParams] = useState({});

  useEffect(() => {
    // Parse the search string into an object
    const params = new URLSearchParams(search);
    const queryObject = {
      searchCity: params.get("searchCity") || "",
      checkInDate: params.get("checkInDate") || "",
      checkOutDate: params.get("checkOutDate") || "",
      guestCount: params.get("guestCount") || "",
    };

    // Set the parsed query parameters to state
    setQueryParams(queryObject);
  }, [search]);

  return queryParams;
}

const MarketplaceNFT = () => {
  const nftService = new NFTService();
  const dispatch = useDispatch();
  const [allNFTsForUsers, setAllNFTsForUsers] = useState([]);
  const [filteredNFTsForUsers, setFilteredNFTsForUsers] = useState([]);
  const queryParams = useQueryParams();

  const removeOffer = (offerId) => {
    setAllNFTsForUsers((prev) =>
      prev.filter((offer) => offer.offerId !== offerId)
    );
  };

  useEffect(() => {
    const getAllNftSellOffers = async () => {
      try {
        dispatch(setShowScreenLoader(true));
        const response = await nftService.getSellOffersForAllUsers();
        const preparedResponse = getCleanedDataForNFT(response);
        console.log("All NFTs for users", preparedResponse);
        setAllNFTsForUsers(preparedResponse);
        setFilteredNFTsForUsers(preparedResponse);
      } catch (err) {
        console.log(err);
      } finally {
        dispatch(setShowScreenLoader(false));
      }
    };
    getAllNftSellOffers();
  }, []);

  useEffect(() => {
    const filteredNFTs = allNFTsForUsers?.filter((nft) => {
      // If any of the query parameters are missing, return the nft
      if (
        !queryParams.searchCity ||
        !queryParams.checkInDate ||
        !queryParams.checkOutDate
      ) {
        return true;
      }

      // Otherwise, filter based on the query parameters
      return (
        nft?.hotelDetails?.Location?.City === queryParams.searchCity &&
        nft?.reservationDetails?.FromDate === queryParams.checkInDate &&
        nft?.reservationDetails?.ToDate === queryParams.checkOutDate
      );
    });

    setFilteredNFTsForUsers(filteredNFTs);
  }, [queryParams, allNFTsForUsers]);

  return (
    <div className="sell-offer-list-container">
      <>
        {filteredNFTsForUsers.length > 0 ? (
          <div className="sell-offers-list">
            {allNFTsForUsers.map((offer, index) => (
              <SellOfferForUser
                key={index}
                offer={offer}
                accept_user_mode={true}
                removeOffer={removeOffer}
              />
            ))}
          </div>
        ) : (
          <div className="no-fferrs-container">
            <div className="no-offers-sub-container">
              <FaExclamationCircle size={40} className="no-offers-icon" />
              <h3 className="no-offers-title">
                Oops! No sell offers available at the moment
              </h3>
            </div>
          </div>
        )}
      </>
    </div>
  );
};

export default MarketplaceNFT;
