import { FaStore } from "react-icons/fa";
import { GiCardExchange } from "react-icons/gi";
import { MdLocalOffer } from "react-icons/md";

export const links_usernft = [
  {
    id: 1,
    title: "Marketplace",
    icon: FaStore,
  },
  {
    id: 2,
    title: "My Offers",
    icon: MdLocalOffer,
  },
  {
    id: 3,
    title: "My NFTs",
    icon: GiCardExchange,
  },
];
