import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  marketplace: true,
  mynft: false,
  myselloffers: false,
};

// Function to reset all values to 0
const resetState = (object) => {
  for (const key in object) {
    object[key] = false;
  }
};

const setState = (object, propKey) => {
  for (const key in object) {
    if (key === propKey) {
      object[key] = true;
    }
  }
};

const nFTSelectBarSlice = createSlice({
  name: "nFTSelectBar",
  initialState,
  reducers: {
    setNftShowComponent: (state, action) => {
      resetState(state);
      setState(state, action.payload);
    },
  },
});

export default nFTSelectBarSlice.reducer;
export const { setNftShowComponent } = nFTSelectBarSlice.actions;
